<template>
    <div class="sector">
        <SanackBar
            :activated="snackActivated"
            :success="snackSuccess"
            :message="snackMessage"
            @closeSnackBar="closeSnack()" />

        <div class="form">
            <div class="form-head">
                <h1 class="head-title">{{ titles.sector }}</h1>
            </div>

            <v-chip class="ma-2" color="primary" v-if="isEditionMode">
                <v-icon left> mdi-database </v-icon>
                id: {{ actor.id }}
            </v-chip>

            <div class="form-fields">
                <div class="field-box field-name">
                    <label>Nome</label>
                    <v-text-field
                        v-model="actor.name"
                        ref="name"
                        type="name"
                        filled
                        rounded
                        dense>
                    </v-text-field>
                </div>

                <div class="field-box field-submit">
                    <SubmitFormButton
                        :title="titles.submitButton"
                        :disabled="formSubmited"
                        @submitForm="submitForm()" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import SanackBar from '@/components/snack/SanackBar.vue';
    import SubmitFormButton from '@/components/SubmitFormButton.vue';
    import { filterPropertiesInObject } from '@/utils/filterProperties';
    import { generateSlug } from '@/modules/admin/helpers/generateSlug';
    import { parseDTO } from '@/utils/parseDTO';

    export default {
        name: 'FormActor',
        components: { SanackBar, SubmitFormButton },
        data() {
            return {
                isEditionMode: false,
                snackActivated: false,
                snackSuccess: false,
                snackMessage: null,
                formSubmited: false,
                actor: {
                    id: null,
                    name: null,
                    slug: null
                },
                titles: {
                    sector: null,
                    submitButton: null
                }
            };
        },
        mounted() {
            this.checkIsEditionMode();
        },
        watch: {
            actor: {
                handler(e) {
                    this.monitoreActorProperties(e);
                },
                deep: true
            }
        },
        computed: {
            ...mapGetters({
                actorStore: 'getDataActor'
            }),
            monitoreActorProperties() {
                return (e) => {
                    e.slug = e.name ? generateSlug(e.name) : null;
                };
            }
        },
        methods: {
            async checkIsEditionMode() {
                if (this.actorStore.isEditionMode) {
                    this.titles.sector = 'Editar ator';
                    this.titles.submitButton = 'Atualizar';

                    this.isEditionMode = true;

                    this.actor.id = this.actorStore.id;

                    await this.serviceLoadingActor();
                } else {
                    this.titles.sector = 'Criar ator';
                    this.titles.submitButton = 'Criar';
                }
            },
            activeSnack(success, message) {
                this.snackActivated = true;
                this.snackSuccess = success;
                this.snackMessage = message;
            },
            closeSnack() {
                this.snackActivated = false;
            },
            goToAfterSubmitForm() {
                this.$router.push({ name: 'actorsAdmin' });
            },
            validateSubmitForm() {
                const snack = (message) => this.activeSnack(false, message);

                const validateActorName = (name) => {
                    if (!name) {
                        snack('Preencha o campo nome!');
                        return true;
                    }

                    if (name.length < 3) {
                        snack('O nome deve conter no mínimo 3 caracteres!');
                        return true;
                    }

                    if (name.length > 16) {
                        snack('O nome deve conter no máximo 16 caracteres!');
                        return true;
                    }

                    return false;
                };

                const errors = [validateActorName(this.actor.name)];

                return !errors.some((e) => e);
            },
            filterObject(actor) {
                const propertiesToFilter = ['id', 'name', 'slug'];

                return filterPropertiesInObject(actor, propertiesToFilter);
            },
            async serviceLoadingActor() {
                this.$store.commit('setGlobalLoading', true);

                const actorId = this.actorStore.id;

                this.$service.http['admin']
                    .getActor(actorId)
                    .then((result) => {
                        const record = result.data.doc;

                        const recordsPropertiesFiltered = this.filterObject(record);

                        const dataParsed = parseDTO(recordsPropertiesFiltered);

                        this.actor = dataParsed;
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            },
            async serviceUpdateActor() {
                await this.$service.http['admin']
                    .updateActor(this.actor.id, this.actor)
                    .then((_) => {
                        this.activeSnack(true, 'Ator atualizado!');

                        setTimeout(() => {
                            this.goToAfterSubmitForm();
                        }, 3000);
                    })
                    .catch((e) => {
                        console.error(e);

                        this.activeSnack(
                            false,
                            'Ocorreu um erro no processamento do endpoint!'
                        );
                    });
            },
            async serviceCreateActor() {
                const dataParsed = parseDTO(this.actor);

                this.$service.http['admin']
                    .createActor(dataParsed)
                    .then((_) => {
                        this.activeSnack(true, 'Ator cadastrado!');

                        setTimeout(() => {
                            this.goToAfterSubmitForm();
                        }, 2000);
                    })
                    .catch((e) => {
                        console.error(e);

                        this.activeSnack(
                            false,
                            'Ocorreu um erro no processamento do endpoint!'
                        );
                    })
                    .finally((_) => {
                        this.formSubmited = false;

                        this.$store.commit('setGlobalLoading', false);
                    });
            },
            async submitForm() {
                if (!this.validateSubmitForm()) return;

                this.$store.commit('setGlobalLoading', true);

                this.formSubmited = true;

                if (this.isEditionMode) {
                    await this.serviceUpdateActor();
                } else {
                    await this.serviceCreateActor();
                }

                this.formSubmited = false;

                this.$store.commit('setGlobalLoading', false);
            }
        }
    };
</script>

<style scoped>
    .sector {
        position: relative;
        width: 350px;
    }

    .sector-head-title {
        font-size: 26px;
    }

    .field-name {
        margin-top: 20px;
    }

    .field-submit {
        margin-top: 10px;
    }

    .field-date-hour {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .field-date-hour .field-date,
    .field-date-hour .field-hour {
        width: 48%;
    }
</style>
